import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav"
export default class extends Controller {
  static targets = ["info"];

  connect() {
    console.log("Info controller connected");
  }

  toggleInfo() {
    this.infoTarget.classList.remove("slide--out");
    this.infoTarget.classList.add("slide--in");
    this.infoTarget.classList.toggle("hidden");
    document.body.classList.add("modal--open");
  }

  closeInfo() {
    this.infoTarget.classList.remove("slide--in");
    this.infoTarget.classList.add("slide--out");
    this.infoTarget.classList.add("hidden");
    document.body.classList.remove("modal--open");
  }
}
