import "~/stylesheets/application.css";
import "~/controllers";
import "@hotwired/turbo-rails";

console.log("Vite ⚡️ Rails");

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails",
);
